import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './Termsconditions.css';

const Termscondition = () => {
    return (
        <>
            <Header />
            <br />
            <br />
            <div className="terms-container">
                <div className="termsAndConditions fadeIn">
                    <h1 className="termsAndConditionsHeading">Terms of Service</h1>
                 
                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading bookingHeading">1. Booking and Payment</h4>
                        <p className="termsParagraph">
                            <strong>Booking Confirmation:</strong> All bookings must be confirmed through our official channels (website, email, or phone).
                            <br />
                            <strong>Payment Method:</strong> A valid payment method is required to secure your reservation.
                            <br />
                            <strong>Payment Requirements:</strong> Full or partial payment may be required at the time of booking, depending on the booking policy.
                            <br />
                            <strong>Cancellation Policy:</strong>
                            <ul className="policyList">
                                <li>Cancellations made up to 7 days before check-in will receive a full refund.</li>
                                <li>Cancellations made within 7 days of the check-in date will incur a charge of 50% of the total booking amount.</li>
                                <li>No refunds will be provided for cancellations made within 48 hours of check-in.</li>
                            </ul>
                        </p>
                        <div className="secionLine lineColorBlue"> </div>
                    </div>

                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading checkInHeading">2. Check-In and Check-Out</h4>
                        <p className="termsParagraph">
                            <strong>Standard Check-In:</strong> 2:00 PM
                            <br />
                            <strong>Standard Check-Out:</strong> 11:00 AM
                            <br />
                            <strong>Early Check-In/Late Check-Out:</strong> Subject to availability and additional charges may apply.
                            <br />
                            <strong>ID Requirement:</strong> Guests are required to present a valid photo ID (passport, driver’s license, or government-issued ID) upon check-in.
                        </p>
                        <div className="secionLine lineColorOrange"> </div>
                    </div>

                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading guestResponsibilitiesHeading">3. Guest Responsibilities</h4>
                        <p className="termsParagraph">
                            <strong>Property Rules:</strong> Guests must adhere to all property rules, including noise regulations and smoking restrictions. Smoking is prohibited indoors but allowed in designated outdoor zones.
                            <br />
                            <strong>Property Condition:</strong> Guests are responsible for maintaining the property in good condition. Damages caused by guests will be charged to their account.
                        </p>
                        <div className="secionLine lineColorGreen"> </div>
                    </div>

 
                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading activitiesHeading">4. Activities and Services</h4>
                        <p className="termsParagraph">
                            <strong>Risk of Participation:</strong> Participation in farmstay activities (e.g., hiking, jungle safaris, cattle feeding) is at the guest’s own risk. Abhyaranyan Farmstay is not liable for injuries or accidents.
                            <br />
                            <strong>Safety Instructions:</strong> Guests must follow all safety instructions provided by staff.
                        </p>
                        <div className="secionLine lineColorBlue"> </div>
                    </div>

         
                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading liabilityHeading">5. Liability</h4>
                        <p className="termsParagraph">
                            <strong>Personal Belongings:</strong> Abhyaranyan Farmstay is not responsible for loss, theft, or damage to personal belongings during your stay.
                            <br />
                            <strong>Natural Events:</strong> Natural events (e.g., weather changes, wildlife encounters) may impact planned activities. The management reserves the right to cancel or modify activities due to such events.
                        </p>
                        <div className="secionLine lineColorOrange"> </div>
                    </div>

                </div>
            </div>
            <br />  
            <Footer />
        </>
    );
}

export default Termscondition;
