import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './Privacy.css';

const Privacy = () => {
    return (
        <>
            <Header />
            <br />
            <br />
            <div className="terms-container">
                <div className="termsAndConditions fadeIn">
                    <h1 className="termsAndConditionsHeading">Privacy Policy</h1>
                    <p className="termsParagraphIntro">
                        Your privacy is important to us at Abhyaranyan Farmstay. This Privacy Policy outlines how we collect, use, and protect your personal information.
                    </p>

                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading privacyHeading">1. Information We Collect</h4>
                        <p className="termsParagraph">
                            - Personal details such as name, contact information, and payment data during the booking process. <br />
                            - Additional preferences (e.g., dietary restrictions, activity interests) to enhance your stay. <br />
                            - Usage data from our website to improve user experience and services.
                        </p>
                        <div className="sectionLine lineColorBlue"></div>
                    </div>

                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading privacyHeading">2. How We Use Your Data</h4>
                        <p className="termsParagraph">
                            - To manage reservations and provide personalized services. <br />
                            - For communication regarding your booking, updates, offers, or promotions (with your consent). <br />
                            - To analyze website usage and improve our services.
                        </p>
                        <div className="sectionLine lineColorOrange"></div>
                    </div>

                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading privacyHeading">3. Data Protection and Sharing</h4>
                        <p className="termsParagraph">
                            - Your data is securely stored and protected against unauthorized access. <br />
                            - We do not sell or rent your data to third parties but may share information with trusted service providers (e.g., payment gateways or booking platforms) for operational purposes.
                        </p>
                        <div className="sectionLine lineColorGreen"></div>
                    </div>

                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading privacyHeading">4. Your Rights</h4>
                        <p className="termsParagraph">
                            - You have the right to access, update, or delete your personal data. Requests can be sent to our official contact email. <br />
                            - You may opt out of promotional communications at any time.
                        </p>
                        <div className="sectionLine lineColorBlue"></div>
                    </div>
                </div>
            </div>
            <br />
            <Footer />
        </>
    );
};

export default Privacy;
