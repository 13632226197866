import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './TeamSection.css';
import Header from './Header';
import Footer from './Footer';


const About = () => {
    const [aboutData, setAboutData] = useState([]);
    const [banners, setBanners] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [blogData, setBlogData] = useState([]);


    useEffect(() => {

        const fetchBanners = async () => {
            try {
                const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/BannerList');
                if (Array.isArray(response.data.data.aboutlist)) {
                    setBanners(response.data.data.aboutlist);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching banners:', error);
            }
        };


        axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/AboutUspage')
            .then(response => {
                setAboutData(response.data.data.about);

                setTeamData(response.data.data.team);
                setTestimonials(response.data.data.testmonial);
                setBlogData(response.data.data.blog);
            })
            .catch(error => {
                console.error('Error fetching about data:', error);
            });
    }, []);

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "gray", left: 0 }}
                onClick={onClick}
            >
                &lt;
            </div>
        );
    };

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "gray", right: 0 }}
                onClick={onClick}
            >
                &gt;
            </div>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    useEffect(() => {
        const fetchBanners = async () => {
            try {
                const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/BannerList');
                console.log('API Response:', response.data); // Check the response structure
                if (Array.isArray(response.data.data.aboutlist)) {
                    setBanners(response.data.data.aboutlist);
                    console.log("-----", response.data.data.aboutlist);
                } else {
                    console.error('Unexpected response format:', response.data);
                }

            } catch (error) {
                console.error('Error fetching banners:', error);
            }
        };

        fetchBanners();
    }, []);


    return (
        <div>
            <Header />

            {Array.isArray(banners) && banners.map((banner, index) => (
                <div className="home_section hotel_layout slide-1 p-0" key={index}>
                    <div>
                        <div className="home">
                            <img
                                src={`https://admin.abhyaranyan.com/RoomsUploadimage/${banner.bannerImage}`}
                                className="img-fluid lazyload bg-img "
                                alt=""
                            />
                            <div className="home-content">
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}




            <section className="about-section three-image about_page animated-section section-b-space">
                <div className="animation-section">
                    {/* Animation elements */}
                    <div className="cross po-1"></div>
                    <div className="cross po-2"></div>
                    <div className="cross po-3"></div>
                    <div className="round po-4"></div>
                    <div className="round po-5"></div>
                    <div className="round r-2 po-6"></div>
                    <div className="round r-2 po-7"></div>
                    <div className="round r-y po-8"></div>
                    <div className="round r-y po-9"></div>
                    <div className="square po-10"></div>
                    <div className="square po-11"></div>
                    <div className="square s-2 po-12"></div>
                </div>
                <div className="container">
                    <div className="title-1">
                        <span className="title-label">About</span>
                        <h2>about us</h2>
                    </div>
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="image-section">
                                {aboutData.map((item, index) => (
                                    <div className="img-box" key={index}>
                                        <img
                                            src={`https://admin.abhyaranyan.com/RoomsUploadimage/${item.image}`} // Assuming the image URL is based on this pattern
                                            data-tilt
                                            data-tilt-perspective="110"
                                            data-tilt-speed="400"
                                            data-tilt-max="1.2"
                                            className="img-fluid blur-up lazyload"
                                            alt={item.title}
                                        />
                                        <div className="title-box">
                                            <h3>{item.type.split(' ')[0]}</h3>
                                            <h6>{item.type.split(' ')[1]}</h6>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="about-text">
                                <div>
                                    
                                   
                                    <h3>{aboutData[0]?.description.split('\r\n')[0]}</h3>
                                    <p>{aboutData[0]?.description.split('\r\n')[1]}</p>
                                    {/* <img src="../assets/images/mix/signature.png" class="img-fluid blur-up lazyload" alt="" /> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="team-section animated-section section-b-space">
                <div className="animation-section">
                    <div className="cross po-1"></div>
                    <div className="cross po-2"></div>
                    <div className="cross po-3"></div>
                    <div className="round po-4"></div>
                    <div className="round po-5"></div>
                    <div className="round r-2 po-6"></div>
                    <div className="round r-2 po-7"></div>
                    <div className="round r-y po-8"></div>
                    <div className="round r-y po-9"></div>
                    <div className="square po-10"></div>
                    <div className="square po-11"></div>
                    <div className="square s-2 po-12"></div>
                </div>
                <div className="container">
                    <div className="title-1">
                        <span className="title-label">Team</span>
                        <h2>our team</h2>
                    </div>
                    <Slider {...settings} className="team-slider no-arrow">
                        {teamData.map(member => (
                            <div key={member.id}>
                                <div className="team-box">
                                    <div className="img-part">
                                        <img
                                            src={`https://admin.abhyaranyan.com/RoomsUploadimage/${member.image}`}
                                            className="img-fluid blur-up lazyload"
                                            alt={member.title}
                                        />
                                    </div>
                                    <div className="team-content">
                                        <h3>{member.title}</h3>
                                        <h6>{member.description}</h6>
                                    </div>
                                    <div className="team-social">
                                        <a href="#" className="social-box">
                                            <img src="../assets/images/icon/social/google.png" className="img-fluid blur-up lazyload" alt="Google" />
                                        </a>
                                        <a href="#" className="social-box">
                                            <img src="../assets/images/icon/social/twitter.png" className="img-fluid blur-up lazyload" alt="Twitter" />
                                        </a>
                                        <a href="#" className="social-box">
                                            <img src="../assets/images/icon/social/facebook.png" className="img-fluid blur-up lazyload" alt="Facebook" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>

            <section className="testimonial_section section-b-space">
                <div className="container">
                    <div className="title-1">
                        <span className="title-label">our</span>
                        <h2 className="pb-0">our guests love us</h2>
                    </div>
                    <div className="row">
                        <div className="offset-lg-1 col-lg-10">
                            <Slider {...settings} className="slide-1 arrow-classic">
                                {testimonials.map((testimonial, index) => (
                                    <div key={index}>
                                        <div className="testimonial">
                                            <div className="top-part">
                                                <div className="img-part">
                                                    <div className="animation-circle-inverse">
                                                        <i></i><i></i><i></i>
                                                    </div>
                                                    <img
                                                        src={`https://admin.abhyaranyan.com/RoomsUploadimage/${testimonial.image}`}
                                                        className="img-fluid blur-up lazyload"
                                                        alt={`Customer Avatar ${index + 1}`}
                                                    />
                                                    <i className="fas fa-heart heart-icon"><span className="effect"></span></i>
                                                </div>
                                            </div>
                                            <div className="bottom-part">
                                                <p>{testimonial.description}</p>
                                                <h3>{testimonial.title}</h3>
                                                <div className="rating">
                                                    {[...Array(5)].map((_, i) => (
                                                        <i
                                                            key={i}
                                                            className={`fas fa-star ${i < parseInt(testimonial.type) ? '' : 'far'}`}
                                                        ></i>
                                                    ))}
                                                </div>
                                                <div className="quote-icon">
                                                    <i className="fas fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>


            



            <Footer />
        </div>
    );
};


export default About;
