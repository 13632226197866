import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css'
const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleToggleNav = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div>
            {/* <header className="overlay-black">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="menu">
                               
                                <nav>
                                    <div className="main-navbar">
                                        <div id="mainnav">
                                            <div className="toggle-nav" onClick={handleToggleNav}>
                                                <i className="fa fa-bars sidebar-bar" />
                                            </div>
                                            <div className={`menu-overlay ${isMenuOpen ? 'open' : ''}`} />
                                            <ul className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
                                                <li className="back-btn">
                                                    <div className="mobile-back text-end" onClick={handleToggleNav}>
                                                        <span>Back</span>
                                                        <i
                                                            aria-hidden="true"
                                                            className="fa fa-angle-right ps-2"
                                                        />
                                                    </div>
                                                </li>
                                                <li className="dropdown">
                                                    
                                                </li>
                                                <li className="dropdown">
                                                   
                                                </li>

                                                <li className="mega-menu">
                                                    
                                                </li>
                                                <li className="dropdown">
                                                    
                                                </li>
                                                <li className="dropdown">
                                                   
                                                </li>
                                                <li className="dropdown">
                                                    
                                                </li>
                                                <li className="mega-menu">
                                                    
                                                </li>

                                                <li className="dropdown">
                                                    
                                                </li>

                                                <li className="dropdown">
                                                    
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header> */}

            <div id="header">
                <div class="container">
                    <nav className="navbar navbar-expand-lg navbar-dark">
                        <a className="navbar-brand d-block d-lg-none" href="#">
                            <div className="brand-logo">
                                <Link to="/">
                                    <img
                                        src="../assets/images/icon/logo13.png"
                                        alt=""
                                        className="img-fluid lazyload"
                                        style={{ height: 66, width: 198 }}
                                    />
                                </Link>
                            </div>
                        </a>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link to="/" className="nav-link menu-title">
                                        Home
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/About" className="nav-link menu-title">
                                        About Us
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/Experiences" className="nav-link menu-title">
                                        Experiences
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/Accommodation" className="nav-link menu-title">
                                        Accommodation
                                    </Link>
                                </li>

                            </ul>
                            <a className="navbar-brand d-none d-lg-block" href="#">
                                <div className="brand-logo">
                                    <Link to="/">
                                        <img
                                            src="../assets/images/icon/logo13.png"
                                            alt=""
                                            className="img-fluid lazyload"
                                            style={{ height: 66, width: 198 }}
                                        />
                                    </Link>
                                </div>
                            </a>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link to="/Dining" className="nav-link menu-title">
                                        Dining
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/Orchid" className="nav-link menu-title">
                                        The Orchard
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/Packages" className="nav-link menu-title">
                                        Package
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/Gallery" className="nav-link menu-title">
                                        Gallery
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/Profilelogin" className="nav-link menu-title">
                                        <i className="fas fa-user"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>

                </div>
            </div>
        </div>
    );
};

export default Header;
