import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './Blogdetails.css'; 
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';

const Blogdetails = () => {
    const { id } = useParams();
    const [blogDetails, setBlogDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBlogDetails = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`https://api.abhyaranyan.com/api/Abhyaranyan/Blogbyid?id=${id}`);
                setBlogDetails(response.data.data);
                setError(null);
            } catch (err) {
                setError('Failed to fetch blog details. Please try again.');
                console.error('Error fetching blog details:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogDetails();
    }, [id]);

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    if (!blogDetails) {
        return <div className="no-content">No blog details available.</div>;
    }

    return (
        <>
            <Header />
            <section className="blog-details-section">
                <div className="container"> <br /><br /><br />
                    
                    <div className="blog-image">
                        <img
                            src={`https://admin.abhyaranyan.com/RoomsUploadimage//${blogDetails.image}`}
                            alt={blogDetails.title}
                            className="img-fluid"
                        />
                    </div>
                    <div
                        className="blog-content"
                        dangerouslySetInnerHTML={{ __html: blogDetails.metaDescription }}
                    />
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Blogdetails;
