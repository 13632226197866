import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
const Experiences = () => {
    const [banners, setBanners] = useState([]);
    const [experiences, setExperiences] = useState([]);

    useEffect(() => {
        const fetchBanners = async () => {
            try {
                const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/BannerList');
                if (Array.isArray(response.data.data.experienceslist)) {
                    setBanners(response.data.data.experienceslist);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching banners:', error);
            }
        };

        const fetchExperiences = async () => {
            try {
                const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/Experiences');
                if (Array.isArray(response.data.data.experiences)) {
                    setExperiences(response.data.data.experiences);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching experiences:', error);
            }
        };

        fetchBanners();
        fetchExperiences();
    }, []);

    return (
        <div>
            <Header />

            {Array.isArray(banners) && banners.map((banner, index) => (
                <div key={index} className=" home_section hotel_layout slide-1 p-0">
                    <div>
                        <div className="home" >
                            <img
                                src={`https://admin.abhyaranyan.com/RoomsUploadimage/${banner.bannerImage}`}
                                className="img-fluid lazyload bg-img bg-img1"
                                alt=""
                            />
                            <div className="home-content">
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            <section className="about_section">
                <div className="container">
                    <div className="title-3">
                        <span className="title-label">Experiences</span>
                        <h2>Experiences<span>Experiences</span></h2>
                    </div>
                    <h6 style={{ fontFamily: "Nunito" }}>
                        A stay at Abhayaranyan Farmstay invites you into a mindful and harmonious way of living. Here, our rewilding efforts have transformed the landscape into a thriving haven for diverse flora and fauna. Escape the relentless pace of urban life and reconnect with the serenity of nature.
                        At Abhayaranyan, there’s an array of activities to immerse yourself in. Join hands with those working the farms, or retreat to a quiet corner with your favorite book. Wander through forest trails on a rejuvenating hike, lose yourself in the shapes of clouds while swaying gently in a hammock, or simply let the earth cradle you—sink your toes into soft, damp soil and feel the rich, nourishing texture of the land in your hands.
                    </h6>
                    <br />
                    {experiences.map((experience, index) => (
                        <div key={index} className="row mb-4">
                            {index % 2 === 0 ? (
                                <>
                                    <div className="col-lg-6">
                                        <div className="about_img">
                                            <img src={`https://admin.abhyaranyan.com/RoomsUploadimage/${experience.image}`} className="img-fluid lazyload" alt={experience.title} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="about_content">
                                            <div>
                                                <h5>{experience.title}</h5>
                                                <p>{experience.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-lg-6">
                                        <div className="about_content">
                                            <div>
                                                <h5>{experience.title}</h5>
                                                <p>{experience.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="about_img">
                                            <img src={`https://admin.abhyaranyan.com/RoomsUploadimage/${experience.image}`} className="img-fluid lazyload" alt={experience.title} />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </section>

            <Footer />
        </div>
    );
};



export default Experiences;
