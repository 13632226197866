import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './Disclaimer.css';

const Disclaimer = () => {
    return (
        <>
            <Header />
            <br />
            <br />
            <div className="terms-container">
                <div className="termsAndConditions fadeIn">
                    <h1 className="termsAndConditionsHeading">Disclaimer</h1>
                    <h4 className="spangleWelcome">Welcome to Abhyaranyan Farmstay</h4>
                    <p className="termsParagraphIntro">
                        These Terms govern your stay and interactions with Abhyaranyan Farmstay. By booking or staying at our property, you agree to abide by these terms.
                    </p>

                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading disclaimerHeading">1. Accuracy of Information</h4>
                        <p className="termsParagraph">
                            While we strive to provide accurate and up-to-date information on our website and materials, we cannot guarantee error-free content. Guests are encouraged to confirm details before booking.
                        </p>
                        <div className="sectionLine lineColorBlue"></div>
                    </div>

                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading disclaimerHeading">2. Activity Participation</h4>
                        <p className="termsParagraph">
                            Participation in activities is voluntary and at the guest’s own risk. Abhyaranyan Farmstay is not responsible for injuries, accidents, or discomfort caused during these activities.
                        </p>
                        <div className="sectionLine lineColorOrange"></div>
                    </div>

                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading disclaimerHeading">3. Natural and External Factors</h4>
                        <p className="termsParagraph">
                            Unforeseen events such as extreme weather, wildlife encounters, or other external factors may affect the availability of activities or services. The management is not liable for disruptions caused by such events but will endeavor to provide alternative arrangements where possible.
                        </p>
                        <div className="sectionLine lineColorGreen"></div>
                    </div>

                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading disclaimerHeading">4. Third-Party Services</h4>
                        <p className="termsParagraph">
                            Activities or excursions organized by third-party providers are subject to their terms and conditions. Abhyaranyan Farmstay is not responsible for third-party actions or service quality.
                        </p>
                        <div className="sectionLine lineColorBlue"></div>
                    </div>
                </div>
            </div>
            <br />
            <Footer />
        </>
    );
};

export default Disclaimer;
