import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios';

const Happycustomer = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/Homepage');
        const { data } = response.data;
        setTestimonials(data.testmonialList);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <section className="testimonial-section animated-section">
      <div className="animation-section">
        <div className="cross po-2"></div>
        <div className="round po-5"></div>
        <div className="round r-2 po-6"></div>
        <div className="round r-2 po-7"></div>
        <div className="round r-y po-8"></div>
        <div className="square po-10"></div>
        <div className="square s-2 po-12"></div>
      </div>
      <div className="container">
        <div className="title-3">
          
          <h2>
            our happy customer<span>customer</span>
          </h2>
        </div>
        <div className="slide-1">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div key={index}>
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <div className="testimonial">
                      <div className="left-part">
                        <img
                          src={`https://admin.abhyaranyan.com/RoomsUploadimage/${testimonial.image}`}
                          className="img-fluid lazyload"
                          alt={`Customer Avatar ${index + 1}`}
                        />
                        <div className="design">
                          <i className="fas fa-comments"></i>
                          <i className="fas fa-comments light"></i>
                        </div>
                      </div>
                      <div className="right-part">
                        <p>{testimonial.description}</p>
                        <div className="detail">
                          <div className="rating">
                            {[...Array(5)].map((_, i) => (
                              <i
                                key={i}
                                className={`fas fa-star ${i < 5 ? '' : 'far'}`}
                              ></i>
                            ))}
                          </div>
                          <h6>{testimonial.title}</h6>
                        </div>
                      </div>
                      <div className="quote-icon">
                        <i className="fas fa-quote-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Happycustomer;
