import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './Cookie.css';

const Cookie = () => {
    return (
        <>
            <Header />
            <br />
            <br />
            <div className="terms-container">
                <div className="termsAndConditions fadeIn">
                    <h1 className="termsAndConditionsHeading">Cookie Policy</h1>
                   

                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading cookieHeading">1. What Are Cookies?</h4>
                        <p className="termsParagraph">
                            Cookies are small text files stored on your device that help enhance your experience on our website. 
                            They collect information such as site preferences, usage statistics, and session data.
                        </p>
                        <div className="sectionLine lineColorBlue"></div>
                    </div>

                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading cookieHeading">2. Types of Cookies We Use</h4>
                        <p className="termsParagraph">
                            We use the following types of cookies to improve your experience:
                        </p>
                        <ul className="policyList">
                            <li><strong>Essential Cookies:</strong> Required for core website functions, such as secure logins and navigation.</li>
                            <li><strong>Analytics Cookies:</strong> Collect anonymous data on website usage to help us improve performance and user experience.</li>
                            <li><strong>Functional Cookies:</strong> Save your preferences (e.g., language or region) to provide a tailored experience.</li>
                            <li><strong>Advertising Cookies:</strong> May be used to display relevant advertisements based on your browsing behavior.</li>
                        </ul>
                        <div className="sectionLine lineColorOrange"></div>
                    </div>

                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading cookieHeading">3. Managing Cookies</h4>
                        <p className="termsParagraph">
                            You can accept or decline cookies through your browser settings. Most browsers allow you to block or delete cookies. 
                            However, disabling cookies may affect the functionality of our website.
                        </p>
                        <div className="sectionLine lineColorGreen"></div>
                    </div>

                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading cookieHeading">4. Third-Party Cookies</h4>
                        <p className="termsParagraph">
                            We use third-party tools like Google Analytics to collect anonymous user data. These services may set their own cookies, 
                            which are governed by their respective privacy policies.
                        </p>
                        <div className="sectionLine lineColorBlue"></div>
                    </div>

                    <div className="serviceLeadingSection">
                        <h4 className="sectionHeading cookieHeading">5. Consent</h4>
                        <p className="termsParagraph">
                            By using our website, you agree to the use of cookies as described in this policy. You may withdraw consent at any time 
                            by adjusting your browser settings.
                        </p>
                        <div className="sectionLine lineColorOrange"></div>
                    </div>
                </div>
            </div>
            <br />
            <Footer />
        </>
    );
};

export default Cookie;
